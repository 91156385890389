<template>
	<div class="step">
    <h2 class="step_title"><span class="border-underline">手順</span></h2>
    <div>
      <h3 class="step_sub_title">1.) POAPの保有確認を行います。POAPを保有するチェーンのボタンを押してください。</h3>
          <a href="javascript://" class="step_button" @click="connectMetamask(1)">Ethereum</a>
          <a href="javascript://" class="step_button" @click="connectMetamask(100)">Gnosis Chain</a>
          <p v-if="verified && isHolding">ACSトークン エアドロップ対象</p>
          <p v-if="verified && !isHolding">ACSトークン エアドロップ非対象</p>
        <div class="step_triangle">
          <img src="/img/awwow.png" alt="">
        </div>
    </div>
    <div>
      <h3 class="step_sub_title">2.) Solana Addressの登録<br>（取引所のアドレスではなくPhantomなどのアドレスをご利用ください）</h3>
        <form action="">
          <input type="text" class="step_input" v-model="solanaAddress" :disabled="!isHolding" >
          <a href="javascript://" class="step_button_register" @click="onRegister">Register</a>
        </form>
        <div class="step_triangle">
          <img src="/img/awwow.png" alt="">
        </div>
    </div>
    <div>
      <h3 class="step_sub_title">3.) Compleated</h3>
      <p v-if="registeredAddress">登録済みアドレス: {{ registeredAddress }}</p>
    </div>
  </div>
</template>


<script>
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import Web3 from 'web3';

export default defineComponent({
  data() {
    return {
      errorMessage: '',
      web3: this.web3,
      solanaAddress: '',
    };
  },
  computed: {
    ...mapState(['defaultAccount', 'currentNetworkId', 'verified', 'isHolding', 'SaveAddress', 'registeredAddress']),

  },
  methods: {
    ...mapActions(['pollNetwork', 'pollAccountsAndNetwork', 'fetchRegisteredAddress']),

    async configureMetaMask(expectedNetworkId) {
      const web3 = this.web3.currentProvider;
      if (expectedNetworkId === 5777 && this.currentNetworkId !== 5777) {
        try {
          await web3.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x539' }],
          });
        } catch (switchError) {
          console.error(switchError);
        }
      }
      else if (expectedNetworkId === 43113 && this.currentNetworkId !== 43113) {
        try {
          await web3.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0xa869' }],
          });
        } catch (switchError) {
          console.error(switchError);
        }
      }
      else if (expectedNetworkId === 5 && this.currentNetworkId !== 5) {
        try {
          await web3.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x5' }],
          });
        } catch (switchError) {
          try {
            await web3.request({
              method: 'wallet_addEthereumChain',
              params: [
              {
                  chainId: '0x3',
                  chainName: 'Goerli Test Network',
                  nativeCurrency: {
                    name: 'Ether',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
                  blockExplorerUrls: ['https://goerli.etherscan.io/'],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
            this.errorMessage = addError.message;
          }
        }
      } else if (expectedNetworkId === 1 && this.currentNetworkId !== 1) {
        {
          try {
            await web3.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x1' }],
            });
          } catch (switchError) {
            try {
              await web3.request({
                method: 'wallet_addEthereumChain',
                params: [
                {
                    chainId: '0x1',
                    chainName: 'Ethereum Main Network',
                    nativeCurrency: {
                      name: 'Ether',
                      symbol: 'ETH',
                      decimals: 18,
                    },
                    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
                    blockExplorerUrls: ['https://etherscan.io/'],
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
              this.errorMessage = addError.message;
            }
          }
        }
      }
      else if (expectedNetworkId === 10200 && this.currentNetworkId !== 10200) {
        try {
          await web3.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x27d8' }],
          });
        } catch (switchError) {
          try {
            await web3.request({
              method: 'wallet_addEthereumChain',
              params: [
              {
                  chainId: '0x27d8',
                  chainName: 'Chiado Testnet',
                  nativeCurrency: {
                    name: 'xDAI',
                    symbol: 'xDAI',
                    decimals: 18,
                  },
                  rpcUrls: ['https://rpc.chiadochain.net'],
                  blockExplorerUrls: ['https://blockscout.chiadochain.net'],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
            this.errorMessage = addError.message;
          }
        }
      }
      else if (expectedNetworkId === 100 && this.currentNetworkId !== 100) {
        try {
          await web3.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x64' }],
          });
        } catch (switchError) {
          try {
            await web3.request({
              method: 'wallet_addEthereumChain',
              params: [
              {
                  chainId: '0x64',
                  chainName: 'Gnosis',
                  nativeCurrency: {
                    name: 'xDAI',
                    symbol: 'xDAI',
                    decimals: 18,
                  },
                  rpcUrls: ['https://rpc.gnosischain.com'],
                  blockExplorerUrls: ['https://gnosisscan.io'],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
            this.errorMessage = addError.message;
          }
        }
      }
    },

    async connectMetamask(expectedNetworkId) {
      const web3 = this.web3.currentProvider;
      this.configureMetaMask(expectedNetworkId);
      this.errorMessage = 'Connecting to MetaMask...';
      web3
        .request({ method: 'eth_requestAccounts' })
        .then(async () => {
          this.errorMessage = 'Success: MetaMask connected.';
          this.doPollAccounts = true;
          const pollAccounts = async () => {
            if (!this.doPollAccounts) return;
            try {
              await this.pollAccountsAndNetwork();
            } catch (e) {
              console.error(e);
            }
            setTimeout(pollAccounts, 3000);
          };
          pollAccounts();
        })
        .catch(() => {
          this.errorMessage = 'Error: MetaMask could not get permissions.';
        });
    },

    async onRegister() {
      if(!this.isHolding) return;
      try {
        await this.SaveAddress.methods.setAddressInfo(this.solanaAddress)
          .send({from: this.defaultAccount})
          .on('confirmation', async (confirmationNumber) => {
            if (confirmationNumber === 1) {
              this.fetchRegisteredAddress();
              return true;
            }
          })
          .catch((x) => {
            console.error(x);
            return false;
          });

      } catch (e) {
        console.error(e);
        this.error = 'Could not purchase: insufficient funds or transaction denied.';
      }
    },

  },

  async created() {
    await this.pollNetwork();
    this.web3 = new Web3(Web3.givenProvider);
  },
});
</script>

<style lang='scss'>
</style>
