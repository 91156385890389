import { abi as saveAddressAbi, networks as saveAddressNetworks } from '../../build/contracts/SaveAddress.json';
import { abi as targetNftAbi, networks as targetNftNetworks } from '../../build/contracts/IERC721Enumerable.json';

import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

type Networks = Partial<Record<string, { address: string }>>;
type Abi = any[];

export async function setUpSaveAddress(web3: Web3): Promise<Contract> {
  const networkId = await web3.eth.net.getId();
  const saveAddressAddr = (saveAddressNetworks as Networks)[networkId]!.address;
  const SaveAddress = new web3.eth.Contract(saveAddressAbi as Abi, saveAddressAddr);
  return SaveAddress;
}

export async function setUpTargetNft(web3: Web3): Promise<Contract> {
  const networkId = await web3.eth.net.getId();
  const targetNftAddr = (targetNftNetworks as Networks)[networkId]!.address;
  const TargetNft = new web3.eth.Contract(targetNftAbi as Abi, targetNftAddr);
  return TargetNft;
}