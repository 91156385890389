import Web3 from 'web3';
import { createApp } from 'vue';
import { createStore } from './store';

import App from './App.vue';

const web3 = new Web3(Web3.givenProvider || process.env.VUE_APP_WEB3_FALLBACK_PROVIDER);

const store = createStore(web3);
const app = createApp(App);
app.use(store);
app.mount('#app');
