// import Vue from 'vue';
import Vuex from 'vuex';
import Web3 from 'web3';
import _ from 'lodash';
import { IState } from './interfaces';
import { setUpSaveAddress } from './contracts';

// const sleep = (t: any) => new Promise((resolve) => setTimeout(() => resolve(t), t));
const defaultCallOptions = (state: IState) => ({ from: state.defaultAccount });

export function createStore(web3: Web3) {
  return new Vuex.Store<IState>({
    state: {
      accounts: [],
      defaultAccount: null,
      currentNetworkId: null,

      SaveAddress: null,
      TargetNft: null,

      verified: false,
      isHolding: false,
      registeredAddress: '',
    },

    getters: {
    },

    mutations: {
      setNetworkId(state, payload) {
        state.currentNetworkId = payload;
      },

      setAccounts(state: IState, payload) {
        state.accounts = payload.accounts;

        if (payload.accounts.length > 0) {
          state.defaultAccount = payload.accounts[0];
        }
        else {
          state.defaultAccount = null;
        }
      },

      setSaveAddress(state: IState, payload) {
        state.SaveAddress = payload;
      },

      setTargetNft(state: IState, payload) {
        state.TargetNft = payload;
      },

      updateVerified(state: IState, payload) {
        state.verified = payload;
      },

      updateIsHolding(state: IState, payload) {
        state.isHolding = payload;
      },

      updateRegisteredAddress(state: IState, payload) {
        state.registeredAddress = payload;
      },
    },

    actions: {
      async pollNetwork({ state, commit }) {
        const networkId = await web3.eth.net.getId();

        if(state.currentNetworkId !== networkId) {
          commit('setNetworkId', networkId);
        }
      },

      async pollAccountsAndNetwork({ state, dispatch, commit }) {
        const networkId = await web3.eth.net.getId();

        if(state.currentNetworkId !== networkId) {
          commit('setNetworkId', networkId);
          await dispatch('setUpContracts');
        }

        const accounts = await web3.eth.requestAccounts();

        if (!_.isEqual(state.accounts, accounts)) {
          commit('setAccounts', { accounts });
        }
        await dispatch('verify');
        await dispatch('fetchRegisteredAddress');
      },

      async setUpContracts({ commit }) {
        const SaveAddress = await setUpSaveAddress(web3);
        commit('setSaveAddress', SaveAddress);
        // const TargetNft = await setUpTargetNft(web3);
        // commit('setTargetNft', TargetNft);
      },

      async verify({ state, dispatch, commit }) {
        const { defaultAccount } = state;
        if(!defaultAccount) return;

        if (state.SaveAddress === null) {
          await dispatch('setUpContracts');
        }

        const isHolding = await state.SaveAddress!.methods
          .isHolding(defaultAccount)
          .call(defaultCallOptions(state));
        if(state.isHolding !== isHolding) {
          commit('updateIsHolding', isHolding);
        }
        commit('updateVerified', true);
      },

      async fetchRegisteredAddress({ state, dispatch, commit }) {
        const { defaultAccount } = state;
        if(!defaultAccount) return;

        if (state.SaveAddress === null) {
          await dispatch('setUpContracts');
        }

        const isRegistered = await state.SaveAddress!.methods
          .isRegistered(defaultAccount)
          .call(defaultCallOptions(state));
        if (!isRegistered) return;

        const addressInfo = await state.SaveAddress!.methods
          .getMyAddressInfo()
          .call(defaultCallOptions(state));
        if(state.registeredAddress !== addressInfo.solanaAddress) {
          commit('updateRegisteredAddress', addressInfo.solanaAddress);
        }
      },


    }
  });
}
